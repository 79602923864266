*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --primary-color: #006f8f;
  --secondary-color: #f25a23;
  --tertiary-color: #383c46;
  --dark-grey-1: #747989;
  --dark-grey-2: #acafba;
  --light-grey-1: #cccfd0;
  --light-grey-2: #eaf1f6;
}
body {
  overflow-x: hidden;
  min-height: 110%;
  height: 100%;
  font-family: "Nunito", sans-serif;
  color: #747989;
  font-size: 16px;
}

a {
  &,
  &:link,
  &:visited {
    text-decoration: none;
    color: var(--dark-grey-1);
  }
  &:hover {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

.plancard-logo {
  width: auto;
  max-width: 150px;

  height: 48px;
}
.error {
  color: #d21f26;
  font-size: 14px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #32ba7c;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 73px;
  height: 73px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin-bottom: 28px;
  box-shadow: inset 0px 0px 0px #32ba7c;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke-width: 4;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.plan-type {
  position: absolute;
  top: -10px;
  background: var(--primary-color);
  color: #fff;
  padding: 3.5px 8px;
  left: 0;
  font-size: 14px;
  &:after {
    position: absolute;
    content: "";
    right: -26px;
    top: 0;
    border: 14px solid var(--primary-color);
    border-right-color: transparent;
  }
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 50px #32ba7c;
  }
}

.hide-legend legend {
  display: none;
}
.radio-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.radio-container label {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  background: #f1f1f1;
  border-radius: 5px;
  color: #626262;
  padding: 8px 26px;
  cursor: pointer;
}
.radio-container > input {
  display: none;
}
.radio-container > input:checked {
  & + label {
    background: #016d91;
    color: #fff;
  }
}

@media (max-width: 768px) {
  .radio-container label {
    font-size: 14px;
    padding: 8px 20px;
  }
  .radio-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
