@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700");

.content {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999999;
}

.content .shareButton.main .share,
.content .shareButton.main .close,
.content .shareButton.main .check {
  position: absolute;
  top: 1rem;
  left: 1rem;
  transition: all 150ms;
}
.content .shareButton.main .share,
.content .shareButton.main.open .close,
.content .shareButton.main.sent .check {
  transform: rotate(0) scale(1);
  opacity: 1;
}
.content .shareButton.main .close,
.content .shareButton.main.open .share,
.content .shareButton.main .check,
.content .shareButton.main.sent .share {
  opacity: 0;
  transform: rotate(90deg) scale(0);
}

.shareButton,
.shareButton.open {
  border: none;
  border-radius: 50%;
  background: #fff;
  padding: 1rem;
  overflow: hidden;
  outline: none;
  margin: 0.5rem;
  width: 20px;
  height: 20px;
  box-sizing: content-box;
  transition: all 200ms;
  position: relative;
  opacity: 1;
  color: var(--primary-color);
  transform: scale(1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 20px;
  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
}

.shareButton:hover,
.shareButton.open:hover {
  transform: scale(1.1) translateY(-3px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

.shareButton svg,
.shareButton.open svg {
  display: block;
  fill: var(--primary-color);
  width: 24px;
  height: 24px;
  opacity: 1;
  transition: all 150ms;
  transform: scale(1) translate(-50%, -50%);
}

.fb,
.shareButton.open.ig {
  transition-delay: 100ms;
}

.tw,
.shareButton.open.tw {
  transition-delay: 50ms;
}

.ig,
.shareButton.open.fb {
  transition-delay: 0ms;
}

.fb,
.tw,
.ig {
  width: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  opacity: 0;
  transform: scale(0);
}
.fb svg,
.tw svg,
.ig svg {
  width: 0;
  height: 0;
  opacity: 0;
  transform: scale(0);
}

.shareQuotes__wrapper {
  width: 100%;
  height: 60px;
  border: 1px solid #eaf1f6;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
