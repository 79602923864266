.header {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__list {
    display: inline-flex;
    list-style: none;
    gap: 24px;
    align-items: center;
    margin-bottom: 0;
    font-size: 20px;
  }

  //   &__logo {
  //     &-wrapper {
  //     }
  //   }
}

@media (max-width: 768px) {
  .header {
    &__logo {
      height: 50px;
    }
  }
}
